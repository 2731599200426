<template>
  <v-select
    :id="id"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    :readonly="readonly"
    :items="items"
    :rules="rules"
    :item-text="itemText"
    :item-value="itemValue"
    :dense="dense"
    :class="{ ...customClass, 'mt-3': !hideTopMargin }"
    :hide-details="hideDetails"
    outlined
    v-model="selectinput"
    :placeholder="placeholder"
    :append-outer-icon="appendOuterIcon"
    v-on:click:append-outer="$emit('click:append-outer', true)"
    v-on:change="$emit('change', selectinput)"
    v-on:keyup="$emit('keyup', $event)"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title v-html="`No ${placeholder} Found.`"></v-list-item-title>
      </v-list-item>
    </template>

    <!-- <template v-slot:prepend-item> 23 </template> -->
  </v-select>
</template>
<style></style>
<script>
export default {
  name: "select-input",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [Object, Number, String],
      default: null,
    },
    items: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    customClass: {
      type: String,
      default: "mt-2 pt-0",
    },
    placeholder: {
      type: String,
      default: null,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideTopMargin: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(param) {
        this.selectinput = param;
      },
    },
  },
  data() {
    return {
      selectinput: null,
    };
  },
  mounted() {
    this.selectinput = this.value;
  },
};
</script>
